import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { registerHtml, start, TramOneComponent } from 'tram-one';
import AppHeader from './components/AppHeader';
import NotificationBanner from './components/NotificationBanner';
import MainScreen from './components/MainScreen';
import LoginScreen from './components/LoginScreen';
import './styles.css';
import { useUser } from './hooks/user';

const html = registerHtml({
	AppHeader,
	NotificationBanner,
	MainScreen,
	LoginScreen,
});

const home: TramOneComponent = () => {
	const { isLoggedIn } = useUser();

	const content = isLoggedIn() ? html`<MainScreen />` : html` <LoginScreen />`;

	return html`
		<main>
			<AppHeader />
			<NotificationBanner />
			${content}
		</main>
	`;
};

start(home, '#app');
