import { registerHtml, TramOneComponent, useStore } from 'tram-one';
import { useKeywords } from '../hooks/keywords';

const html = registerHtml();

const KeywordForm: TramOneComponent = () => {
	const workingKeyword = useStore({ value: '', hasCapitals: false });
	const { addKeyword } = useKeywords();

	const onTypingKeyword = (event: KeyboardEvent) => {
		const inputElement = event.target as HTMLInputElement;
		workingKeyword.value = inputElement.value;
		workingKeyword.hasCapitals = !!inputElement.value.match(/[A-Z]/);
	};

	const onAddingKeyword = (event: FormDataEvent) => {
		event.preventDefault();
		workingKeyword.value = '';
		workingKeyword.hasCapitals = false;
		addKeyword(event.target['new-keyword-form'].value.toLowerCase());
	};

	const caseInsensitiveNote = workingKeyword.hasCapitals ? '*keywords are not case-sensitive' : '';

	return html`
		<form class="KeywordForm" onsubmit=${onAddingKeyword}>
			<input
				id="new-keyword-form"
				class="new-keyword-form"
				type="text"
				placeholder="add new keyword"
				onkeydown=${onTypingKeyword}
				value=${workingKeyword.value}
			/>
			<span class="new-keyword-note">${caseInsensitiveNote}</span>
		</form>
	`;
};

export default KeywordForm;
