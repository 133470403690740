import { registerHtml, TramOneComponent } from 'tram-one';
import { useKeywords } from '../hooks/keywords';
import FilterGroup from './FilterGroup';
import FilterSaveControls from './FilterSaveControls';
import FilterRemoveControls from './FilterRemoveControls';
import FilterNotificationsControl from './FilterNotificationsControl';

const html = registerHtml({
	FilterGroup,
	FilterSaveControls,
	FilterRemoveControls,
	FilterNotificationsControl,
});

const FilterSection: TramOneComponent = () => {
	const { getSelectedKeyword } = useKeywords();

	if (getSelectedKeyword() === null) {
		return html`<h2>Create or Select a Keyword</h2>`;
	}

	const keyword = getSelectedKeyword();

	return html`
		<section class="FilterSection">
			<h2 class="filter-section-header">
				Filters for "${keyword}"
				<FilterSaveControls />
			</h2>

			<FilterNotificationsControl />
			<FilterGroup category="subreddits" />
			<FilterGroup category="users" />
			<FilterRemoveControls keyword="${keyword}" />
		</section>
	`;
};

export default FilterSection;
