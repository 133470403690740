import { registerHtml, TramOneComponent, useStore } from 'tram-one';
import { useKeywords } from '../hooks/keywords';

const html = registerHtml();

const FilterRemoveControls: TramOneComponent = () => {
	const removingState = useStore({ isRemoving: false });
	const { getSelectedKeyword, removeKeyword } = useKeywords();

	if (getSelectedKeyword() === null) {
		return html`<div />`;
	}

	const onSetRemoving = () => {
		removingState.isRemoving = true;
	};

	const onCancelRemoving = () => {
		removingState.isRemoving = false;
	};

	const onRemoveKeyword = () => {
		const keyword = getSelectedKeyword();
		removeKeyword(keyword);
	};

	return removingState.isRemoving
		? html`
				<section class="FilterRemoveControls">
					Are you sure you want to remove this keyword?
					<button class="remove-cancel keyword-control" onclick=${onCancelRemoving}>Cancel</button>
					<button class="remove-confirm keyword-control" onclick=${onRemoveKeyword}>Confirm</button>
				</section>
		  `
		: html`
				<section class="FilterRemoveControls">
					<button class="remove-start keyword-control" onclick=${onSetRemoving}>Remove Keyword</button>
				</section>
		  `;
};

export default FilterRemoveControls;
