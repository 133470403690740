import { registerHtml, TramOneComponent } from 'tram-one';
import XControl from './XControl';
import { useKeywords } from '../hooks/keywords';

const html = registerHtml({
	XControl,
});

const KeywordItem: TramOneComponent = ({ keyword, onselect }) => {
	const { getSelectedKeyword, isDirty } = useKeywords();
	const isSelectedKeywordClass = keyword == getSelectedKeyword() ? 'selected' : '';
	const keywordDirtyClass = isDirty(keyword) ? 'dirty' : '';

	return html`
		<span class="KeywordItem ${isSelectedKeywordClass}">
			<button class="keyword-item-select ${keywordDirtyClass}" onclick=${onselect}>${keyword}</button>
		</span>
	`;
};

export default KeywordItem;
