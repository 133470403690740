import { registerHtml, TramOneComponent } from 'tram-one';
import XSvg from './XSvg';

const html = registerHtml({
	XSvg,
});

const XControl: TramOneComponent = ({ onselect, onhover }) => {
	const startDismissing = () => {
		onhover && onhover(true);
	};
	const endDismissing = () => {
		onhover && onhover(false);
	};

	return html`
		<button class="XControl" onclick=${onselect} onmouseenter=${startDismissing} onmouseleave=${endDismissing}>
			<XSvg />
		</button>
	`;
};

export default XControl;
