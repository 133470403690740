import { useGlobalStore, useEffect } from 'tram-one';
import { fetchKeywords, createKeyword, deleteKeyword, updateKeyword } from '../server/redditcomber';
import { Filter, KeywordStore } from '../types';
import { useNotification } from './notification';

const defaultFilters: (keyword: string) => Filter = (keyword: string) => ({
	key: keyword,
	source: 'both',
	subreddits: {
		type: 'blacklist',
		values: new Set(),
	},
	users: {
		type: 'blacklist',
		values: new Set(),
	},
	dirty: true,
});

const blankKeywordStore: () => KeywordStore = () => ({});

const defaultSelectedKeywordStore = {
	selected: null,
};

export const useFilters = (category: string) => {
	const keywordStore = useGlobalStore('KEYWORD_STORE', blankKeywordStore());
	const savedKeywordStore = useGlobalStore('SAVED_KEYWORD_STORE', blankKeywordStore());

	const selectedKeywordStore = useGlobalStore('SELECTED_KEYWORD_STORE', defaultSelectedKeywordStore);

	const keyword = selectedKeywordStore.selected;

	const setType = (newType) => {
		keywordStore[keyword][category].type = newType;
		keywordStore[keyword].dirty = true;
	};

	const getType = () => {
		if (keyword == null) return null;
		return keywordStore[keyword][category].type;
	};

	const getSavedType = () => {
		if (keyword == null || savedKeywordStore[keyword] == null) return null;
		return savedKeywordStore[keyword][category].type;
	};

	const addValue = (newValue) => {
		keywordStore[keyword][category].values.add(newValue);
		keywordStore[keyword].dirty = true;
	};

	const removeValue = (valueToRemove) => {
		keywordStore[keyword][category].values.delete(valueToRemove);
		keywordStore[keyword].dirty = true;
	};

	const getValues = () => {
		if (keyword == null) return [];
		return keywordStore[keyword][category].values;
	};

	const getSavedValues = () => {
		if (keyword == null || savedKeywordStore[keyword] == null) return [];
		return savedKeywordStore[keyword][category].values;
	};

	const getPrefix = () => {
		return category === 'subreddits' ? 'r/' : 'u/';
	};

	return {
		setType,
		getType,
		addValue,
		getValues,
		getPrefix,
		removeValue,
		getSavedType,
		getSavedValues,
	};
};

export const useKeywords = () => {
	const keywordStore = useGlobalStore('KEYWORD_STORE', blankKeywordStore());
	const savedKeywordStore = useGlobalStore('SAVED_KEYWORD_STORE', blankKeywordStore());
	const keywordStoreFetch = useGlobalStore('KEYWORD_STORE_FETCH', { isLoading: false });
	const selectedKeywordStore = useGlobalStore('SELECTED_KEYWORD_STORE', defaultSelectedKeywordStore);
	const { setMessage } = useNotification();

	useEffect(async () => {
		if (keywordStoreFetch.isLoading) {
			return;
		}

		keywordStoreFetch.isLoading = true;
		const fetchedKeywords = await fetchKeywords();
		if (fetchedKeywords.error) {
			setMessage(`Failed to fetch keywords - ${fetchedKeywords.message}`, 'error');
		} else {
			loadFetchedKeywords(fetchedKeywords.data);
		}
	});

	const loadFetchedKeywords = (fetchedKeywords) => {
		Object.keys(fetchedKeywords).forEach((keyword) => {
			copyKeyword(keyword, keywordStore, fetchedKeywords);
			copyKeyword(keyword, savedKeywordStore, fetchedKeywords);
		});

		// if we have any keywords, by default select the first one
		if (Object.keys(fetchedKeywords).length > 0) {
			selectKeyword(Object.keys(fetchedKeywords)[0]);
		}
	};

	const getKeywords = () => {
		return Object.keys(keywordStore);
	};

	const getSelectedKeyword = () => {
		return selectedKeywordStore.selected;
	};

	const selectKeyword = (keywordToSelect) => {
		selectedKeywordStore.selected = keywordToSelect;
	};

	const addKeyword = (newKeyword: string) => {
		// format keyword in all the sane ways (trim whitespace)
		const formattedKeyword = newKeyword.trim();
		// if keyword is blank, don't write it
		if (formattedKeyword === '') {
			return;
		}
		// if keyword already exists, don't reset it
		if (keywordStore[formattedKeyword] !== undefined) {
			return;
		}
		keywordStore[formattedKeyword] = defaultFilters(newKeyword);
		selectKeyword(formattedKeyword);
	};

	const removeKeyword = async (keywordToRemove) => {
		const keywordIsOnServer = keywordStore[keywordToRemove].id;
		if (keywordIsOnServer) {
			const removeResponse = await deleteKeyword(keywordStore[keywordToRemove]);
			if (removeResponse.error) {
				setMessage(`Failed to remove keyword - ${removeResponse.message}`, 'error');
				return;
			} else {
				setMessage(`Successfully removed keyword`, 'success');
			}
		}
		if (selectedKeywordStore.selected === keywordToRemove) {
			// if the keyword we want to remove is the selected one,
			selectKeyword(null);
		}
		delete keywordStore[`${keywordToRemove}`];
	};

	const isDirty = (keyword) => {
		if (keywordStore[keyword] === undefined) return false;
		return keywordStore[keyword].dirty;
	};

	const setSource = (keyword, newSource: 'both' | 'posts' | 'comments') => {
		keywordStore[keyword].source = newSource;
		keywordStore[keyword].dirty = true;
	};

	const getSource = (keyword) => {
		return keywordStore[keyword].source;
	};

	const getSavedSource = (keyword) => {
		if (keyword == null || savedKeywordStore[keyword] == null) return null;
		return savedKeywordStore[keyword].source;
	};

	const copyKeyword = (keyword: string, store: KeywordStore, referenceStore: KeywordStore) => {
		// if we are removing the keyword, then just delete it
		if (referenceStore[keyword] == null) {
			removeKeyword(keyword);
			return;
		}

		// otherwise copy all the properties over
		store[keyword] = defaultFilters(keyword);
		store[keyword].id = referenceStore[keyword].id;
		store[keyword].key = referenceStore[keyword].key;
		store[keyword].source = referenceStore[keyword].source;
		store[keyword].subreddits.type = referenceStore[keyword].subreddits.type;
		store[keyword].subreddits.values = new Set([...referenceStore[keyword].subreddits.values]);
		store[keyword].users.type = referenceStore[keyword].users.type;
		store[keyword].users.values = new Set([...referenceStore[keyword].users.values]);
		store[keyword].dirty = false;
		referenceStore[keyword].dirty = false;
	};

	const saveKeyword = async (keyword) => {
		if (keywordStore[keyword].id) {
			// if there is an id, we want to update the keyword
			const keywordResponse = await updateKeyword(keywordStore[keyword]);
			if (keywordResponse.error) {
				const requiresUpgrade = keywordResponse.status === 402;
				setMessage(`Failed to update keyword - ${keywordResponse.message}`, requiresUpgrade ? 'upgrade' : 'error');
				return;
			} else {
				setMessage('Successfully updated keyword', 'success');
			}
		} else {
			// if there is no id, we need to create the keyword
			const keywordResponse = await createKeyword(keywordStore[keyword]);
			if (keywordResponse.error) {
				const requiresUpgrade = keywordResponse.status === 402;
				setMessage(`Failed to create keyword - ${keywordResponse.message}`, requiresUpgrade ? 'upgrade' : 'error');
				return;
			} else {
				setMessage('Successfully added keyword', 'success');
			}
			keywordStore[keyword].id = keywordResponse.id;
		}
		copyKeyword(keyword, savedKeywordStore, keywordStore);
	};

	const resetKeyword = (keyword) => {
		copyKeyword(keyword, keywordStore, savedKeywordStore);
	};

	return {
		getKeywords,
		selectKeyword,
		getSelectedKeyword,
		addKeyword,
		removeKeyword,
		isDirty,
		saveKeyword,
		resetKeyword,
		setSource,
		getSource,
		getSavedSource,
	};
};
