import { registerSvg, TramOneComponent } from 'tram-one';

const svg = registerSvg();

const XSvg: TramOneComponent = () => {
	return svg`
		<svg class="XSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z">
			</path>
		</svg>
	`;
};

export default XSvg;
