import { useGlobalStore } from 'tram-one';

const defaultNotificationStore = () => ({
	message: '',
	visible: false,
	type: 'success',
});

export const useNotification = () => {
	const notificationStore = useGlobalStore('NOTIFICATION', defaultNotificationStore());

	const getNotificationMessage = () => {
		return notificationStore;
	};

	const setMessage = (message, type) => {
		notificationStore.message = message;
		notificationStore.type = type;
		notificationStore.visible = true;
	};

	const dismissNotification = () => {
		notificationStore.visible = false;
	};

	return {
		getNotificationMessage,
		setMessage,
		dismissNotification,
	};
};
