import { registerHtml, TramOneComponent } from 'tram-one';
import { useKeywords } from '../hooks/keywords';

const html = registerHtml();

const FilterSaveControls: TramOneComponent = () => {
	const { getSelectedKeyword, saveKeyword, resetKeyword, isDirty } = useKeywords();

	if (getSelectedKeyword() === null) {
		return html`<div />`;
	}

	const onSaveKeyword = () => {
		const keyword = getSelectedKeyword();
		saveKeyword(keyword);
	};

	const onResetKeyword = () => {
		const keyword = getSelectedKeyword();
		resetKeyword(keyword);
	};

	const shouldDisplaySaveControls = isDirty(getSelectedKeyword());
	const displaySaveControlsClass = shouldDisplaySaveControls ? 'hideable' : 'hidden';

	return html`
		<section class="FilterSaveControls ${displaySaveControlsClass}">
			<button
				class="reset-control keyword-control"
				onclick=${onResetKeyword}
				${!shouldDisplaySaveControls ? 'disabled' : ''}
			>
				Undo
			</button>
			<button
				class="save-control keyword-control"
				onclick=${onSaveKeyword}
				${!shouldDisplaySaveControls ? 'disabled' : ''}
			>
				Save
			</button>
		</section>
	`;
};

export default FilterSaveControls;
