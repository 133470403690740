import { registerHtml, TramOneComponent } from 'tram-one';
import { useFilters } from '../hooks/keywords';

const html = registerHtml();

const generateInputLabel = (category, filterType) => {
	const categoryName = category === 'subreddits' ? 'subreddit' : 'user';
	const filterTypeAdjective = filterType === 'whitelist' ? 'only' : 'not';
	const oppositeCategory = category === 'subreddits' ? 'users' : 'subreddits';
	const categoryPhrase = category === 'subreddits' ? 'in subreddits' : 'by users';
	const filterTypeTooltip = `You will ${filterTypeAdjective} be notified by posts ${categoryPhrase} added here. This is in addition to any filters set for ${oppositeCategory}.`;

	const filterLabel = filterType === 'whitelist' ? 'Allow List' : 'Block List';

	return html`
		<span>
			Add ${categoryName} to
			<span class="filter-type-label" title=${filterTypeTooltip}>${filterLabel}</span>
		</span>
	`;
};

const FilterForm: TramOneComponent = ({ category, filterType }) => {
	const { addValue, getPrefix } = useFilters(category);

	const inputKey = `filter-input-${category}`;
	const label = generateInputLabel(category, filterType);

	const onAddValue = (event: FormDataEvent) => {
		event.preventDefault();
		addValue(event.target[inputKey].value);
		event.target[inputKey].value = '';
	};

	return html`
		<form class="FilterForm" onsubmit=${onAddValue}>
			<label for=${inputKey}>${label}</label><br />
			<span class="filter-input-section">
				<span class="filter-input-prefix">${getPrefix()}</span>
				<input id=${inputKey} class="filter-input" />
			</span>
		</form>
	`;
};

export default FilterForm;
