import { registerHtml, TramOneComponent } from 'tram-one';
import FilterTag from './FilterTag';
import { useFilters } from '../hooks/keywords';

const html = registerHtml({
	FilterTag,
});

const FilterTags: TramOneComponent = ({ category }) => {
	const { getValues, getSavedValues } = useFilters(category);

	const filterTags = [...getValues()].map((filterValue) => {
		const shouldHighlight = !getSavedValues().has(filterValue);
		return html`<FilterTag category=${category} value=${filterValue} ${shouldHighlight ? 'highlight' : ''} />`;
	});

	return html`<section class="FilterTags">${filterTags}</section>`;
};

export default FilterTags;
