import { registerHtml, TramOneComponent } from 'tram-one';
import useDismissable from '../hooks/dismissable';
import { useNotification } from '../hooks/notification';
import XControl from './XControl';

const html = registerHtml({
	XControl,
});

const NotificationBanner: TramOneComponent = () => {
	const { onHoveringDismiss, dismissClass } = useDismissable();

	const { getNotificationMessage, dismissNotification } = useNotification();
	const { message, type, visible } = getNotificationMessage();

	const onDismissNotification = () => {
		dismissNotification();
	};

	if (!visible) {
		return html` <section class="NotificationBanner"></section>`;
	}

	const link = type === 'upgrade' ? { href: 'https://www.patreon.com/rgood' } : '';
	return html` <section class="NotificationBanner ${dismissClass} notification-${type}">
		<a ${link}>${message}</a>
		<XControl onselect=${onDismissNotification} onhover=${onHoveringDismiss} />
	</section>`;
};

export default NotificationBanner;
