import { registerHtml, TramOneComponent } from 'tram-one';
import graphic from '../../feed_example_square.png';
import { useUser } from '../hooks/user';

const html = registerHtml();

const LoginScreen: TramOneComponent = () => {
	const { login } = useUser();

	return html`
		<section class="LoginScreen">
			<section>
				<h1 class="login-title">Don't miss a single mention!</h1>
				<p class="login-copy">
					Get realtime notifications whenever a phrase or word appears on Reddit. Scans both posts and comments, and
					delivers notifications straight to your Reddit inbox.
				</p>
				<a href="/auth/reddit/login"><button class="login-control">Login with Reddit</button></a>
			</section>
			<img class="login-graphic" src=${graphic} width="100%" />
		</section>
	`;
};

export default LoginScreen;
