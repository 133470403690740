import { registerHtml, TramOneComponent } from 'tram-one';
import FilterSection from './FilterSection';
import KeywordSection from './KeywordSection';

const html = registerHtml({
	KeywordSection,
	FilterSection,
});

const MainScreen: TramOneComponent = () => {
	return html`
		<section class="MainScreen">
			<KeywordSection />
			<FilterSection />
		</section>
	`;
};

export default MainScreen;
