import { registerHtml, TramOneComponent } from 'tram-one';
import { useKeywords } from '../hooks/keywords';

const html = registerHtml();

const sourceValues = [
	{ value: 'both', label: 'Posts and Comments' },
	{ value: 'posts', label: 'Posts only' },
	{ value: 'comments', label: 'Comments only' },
];

const FilterNotificationsControl: TramOneComponent = ({ category }) => {
	const { getSelectedKeyword, getSource, setSource, getSavedSource } = useKeywords();

	if (getSelectedKeyword() === null || getSelectedKeyword() === undefined) {
		return html`<div />`;
	}

	const currentSourceValue = getSource(getSelectedKeyword());
	const sourceOptions = sourceValues.map(({ value, label }) => {
		return html`<option ${currentSourceValue === value ? 'selected' : ''} value=${value}>${label}</option>`;
	});

	const savedSourceValue = getSavedSource(getSelectedKeyword());
	const shouldHighlight = savedSourceValue !== currentSourceValue;

	const onSelectSource = (event) => {
		const newSource = event.target.value;
		setSource(getSelectedKeyword(), newSource);
	};

	return html`
		<section class="FilterNotificationsControl">
			<h3 class="${shouldHighlight ? 'highlight' : ''}">
				<span class="filter-control-header">Notifications for</span>
				<select class="filter-type-dropdown" onchange=${onSelectSource}>
					${sourceOptions}
				</select>
			</h3>
		</section>
	`;
};

export default FilterNotificationsControl;
