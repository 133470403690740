import { registerHtml, TramOneComponent } from 'tram-one';
import { useUser } from '../hooks/user';

const html = registerHtml();

const UserMenu: TramOneComponent = () => {
	const { getUserName, isLoggedIn, logout, getAccountType } = useUser();
	if (!isLoggedIn()) {
		return html`<section />`;
	}

	const userName = getUserName();

	const onSelect = (event) => {
		if (event.target.value === 'logout') {
			logout();
		} else if (event.target.value === 'admin-dashboard') {
			window.open(process.env.ADMIN_DASHBOARD_URL || '/admin');
		} else if (event.target.value === 'upgrade') {
			window.open('https://www.patreon.com/rgood');
		} else if (event.target.value === 'subnotifications') {
			window.open('https://www.reddit.com/r/SubNotifications/');
		}
		event.target.value = 'username';
	};

	const accountType = getAccountType();

	const isAdminAccount = accountType === 'admin';
	const adminDashOption = isAdminAccount ? html`<option value="admin-dashboard">Admin Dashboard</option>` : '';

	const isFreeAccount = accountType === 'free';
	const upgradeOption = isFreeAccount ? html`<option value="upgrade">Upgrade Account</option>` : '';

	return html`
		<select class="UserControl" onchange=${onSelect}>
			<option value="username" class="user-control-username">${userName}</option>
			${adminDashOption} ${upgradeOption}
			<option value="subnotifications">r/SubNotifications</option>
			<option value="logout">Log out</option>
		</select>
	`;
};

export default UserMenu;
