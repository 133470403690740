import { registerHtml, TramOneComponent } from 'tram-one';
import KeywordForm from './KeywordForm';
import KeywordItem from './KeywordItem';
import { useUser } from '../hooks/user';
import { useKeywords } from '../hooks/keywords';

const html = registerHtml({
	KeywordForm,
	KeywordItem,
});

const KeywordSection: TramOneComponent = () => {
	const { getKeyLimit } = useUser();
	const { getKeywords, selectKeyword } = useKeywords();
	const keywordItems = getKeywords().map((keyword) => {
		const onSelectKeyword = () => {
			selectKeyword(keyword);
		};
		return html`<KeywordItem onselect=${onSelectKeyword} keyword=${keyword} />`;
	});
	return html`
		<section class="KeywordSection">
			<h2 class="keyword-header">
				Keywords
				<span class="keyword-limit-label">(${getKeywords().length}/${getKeyLimit()})</span>
			</h2>
			<section>${keywordItems}</section>
			<KeywordForm />
		</section>
	`;
};

export default KeywordSection;
