import { Filter, KeywordStore, ServerKeywordModel, UserSession } from '../types';

type userSessionFunction = () => Promise<{ error: true; message: string } | UserSession>;

export const fetchUserSession: userSessionFunction = async () => {
	try {
		const sessionData = await fetch('/api/profile', {
			credentials: process.env.NODE_ENV === 'production' ? 'same-origin' : 'include',
		});

		const sessionDataObject = await sessionData.json();

		if (sessionDataObject.error) {
			throw { message: sessionDataObject.error, status: sessionData.status };
		}
		return sessionDataObject;
	} catch (error) {
		return { error: true, message: error.message, status: error.status };
	}
};

export const fetchKeywords = async () => {
	try {
		const keywordResponse = await fetch('/api/keywords', {
			credentials: process.env.NODE_ENV === 'production' ? 'same-origin' : 'include',
		});

		const keywords: ServerKeywordModel[] | { error: true } = await keywordResponse.json();

		if (!Array.isArray(keywords) && keywords.error === true) {
			throw { message: keywords.error, status: keywordResponse.status };
		}

		const toSingleKeywordStore = (workingStore: KeywordStore, newKeyword: ServerKeywordModel) => {
			const hasWhitelistedSubreddits = newKeyword?.filters?.whitelist?.subreddits;
			const hasWhitelistedUsers = newKeyword?.filters?.whitelist?.users;

			const subreddits = newKeyword?.filters?.whitelist?.subreddits || newKeyword?.filters?.blacklist?.subreddits || [];
			const users = newKeyword?.filters?.whitelist?.users || newKeyword?.filters?.blacklist?.users || [];

			workingStore[newKeyword.key] = {
				id: newKeyword.id,
				key: newKeyword.key,

				// if there is a legacy source (empty string or null), treat that as comments only.
				source: newKeyword.source === '' || newKeyword.source === null ? 'comments' : newKeyword.source,

				subreddits: {
					type: hasWhitelistedSubreddits ? 'whitelist' : 'blacklist',
					values: new Set(subreddits),
				},

				users: {
					type: hasWhitelistedUsers ? 'whitelist' : 'blacklist',
					values: new Set(users),
				},

				dirty: false,
			};

			return workingStore;
		};

		const serverKeywordClientStore: KeywordStore = keywords.reduce(toSingleKeywordStore, {});

		return {
			error: false,
			data: serverKeywordClientStore,
		};
	} catch (error) {
		return { error: true, message: error.message, status: error.status };
	}
};

const keywordToServerModel = (keywordObject: Filter) => {
	return {
		...{ id: keywordObject.id },
		key: keywordObject.key,
		source: keywordObject.source,
		filters: {
			blacklist: {
				subreddits:
					keywordObject.subreddits.type === 'blacklist' ? Array.from(keywordObject.subreddits.values) : undefined,
				users: keywordObject.users.type === 'blacklist' ? Array.from(keywordObject.users.values) : undefined,
			},
			whitelist: {
				subreddits:
					keywordObject.subreddits.type === 'whitelist' ? Array.from(keywordObject.subreddits.values) : undefined,
				users: keywordObject.users.type === 'whitelist' ? Array.from(keywordObject.users.values) : undefined,
			},
		},
	};
};

export const updateKeyword = async (keywordObject) => {
	try {
		const createKeywordResponse = await fetch('/api/keywords', {
			method: 'PUT',
			credentials: process.env.NODE_ENV === 'production' ? 'same-origin' : 'include',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify(keywordToServerModel(keywordObject)),
		});

		const keywordResponseObject = await createKeywordResponse.json();
		if (keywordResponseObject.error) {
			throw { message: keywordResponseObject.error, status: createKeywordResponse.status };
		}

		return keywordResponseObject;
	} catch (error) {
		return { error: true, message: error.message, status: error.status };
	}
};

export const createKeyword = async (keywordObject: Filter) => {
	try {
		const createKeywordResponse = await fetch('/api/keywords', {
			method: 'POST',
			credentials: process.env.NODE_ENV === 'production' ? 'same-origin' : 'include',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify(keywordToServerModel(keywordObject)),
		});

		const keywordResponseObject = await createKeywordResponse.json();
		if (keywordResponseObject.error) {
			throw { message: keywordResponseObject.error, status: createKeywordResponse.status };
		}

		return keywordResponseObject;
	} catch (error) {
		return { error: true, message: error.message, status: error.status };
	}
};

export const deleteKeyword = async (keywordObject) => {
	try {
		const deleteResponse = await fetch('/api/keywords', {
			method: 'DELETE',
			credentials: process.env.NODE_ENV === 'production' ? 'same-origin' : 'include',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify(keywordToServerModel(keywordObject)),
		});

		// right now delete doesn't return anything, but if it ever does, we'll want this
		// const deleteResponseObject = await deleteResponse.json();
		// if (deleteResponseObject.error) {
		// 	throw { message: deleteResponseObject.error };
		// }
		return { error: false };
	} catch (error) {
		return { error: true, message: error.message };
	}
};
