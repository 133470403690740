import { useGlobalStore, useEffect } from 'tram-one';
import { fetchUserSession } from '../server/redditcomber';
import { useNotification } from './notification';

const defaultUserStore = () => ({
	loggedIn: false,
	loading: false,
	id: null,
	username: '',
	accountType: '',
	keyLimit: 0,
});

export const useUser = () => {
	const userStore = useGlobalStore('USER_STORE', defaultUserStore());
	const { setMessage } = useNotification();
	useEffect(async () => {
		if (userStore.loading) {
			return;
		}

		userStore.loading = true;
		const userSession = await fetchUserSession();
		if (userSession.error === true && userSession.status !== 401) {
			setMessage(`Failed to load profile data - ${userSession.message}`, 'error');
		} else {
			if (userSession.username === undefined) {
				// do nothing, we still need to log in!
			} else {
				login(userSession);
			}
		}
	});

	// eventually we may want to fetch the snoo avatar, for now, don't
	// useEffect(() => {
	// 	useEffect(async () => {
	// 		const account = await fetch('https://www.reddit.com/user/jrjurman/about.json');
	// 		const accountData = await account.json();
	// 		console.log(accountData);
	// 	});
	// })

	const login = ({ id, username, account_type, key_limit }) => {
		userStore.loggedIn = true;

		// TODO process login data
		userStore.id = id;
		userStore.username = username;
		userStore.accountType = account_type;
		userStore.keyLimit = key_limit;
	};

	const logout = () => {
		userStore.loggedIn = false;
		userStore.id = null;
		userStore.username = '';
		userStore.accountType = '';
		userStore.keyLimit = 0;
		window.location.href = '/logout';
	};

	const isLoggedIn = () => {
		return userStore.loggedIn;
	};

	const getUserName = () => {
		return userStore.username;
	};

	const getKeyLimit = () => {
		return userStore.keyLimit;
	};

	const getAccountType = () => {
		return userStore.accountType;
	};

	return {
		login,
		logout,
		isLoggedIn,
		getUserName,
		getKeyLimit,
		getAccountType,
	};
};
