import { registerHtml, TramOneComponent } from 'tram-one';
import UserControl from './UserControl';
import logo from '../../assets/SVG/redditcomber_FULL-color.svg';

// const svgFullLogoPath = '../../assets/redditcomber-FULL/color/SVG/redditcomber_FULL-color.svg';
// const resolvedPath = new URL(svgFullLogoPath, import.meta.url);

const html = registerHtml({
	UserControl,
});

const AppHeader: TramOneComponent = (props, children) => {
	return html`
		<header class="AppHeader">
			<section class="app-header-content">
				<a href="/">
					<h1 class="app-title">
						<img class="logo" src="${logo}" title="RedditComber" />
					</h1>
				</a>
				<UserControl />
			</section>
		</header>
	`;
};

export default AppHeader;
