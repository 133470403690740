import { registerHtml, TramOneComponent } from 'tram-one';
import XControl from './XControl';
import { useFilters } from '../hooks/keywords';
import useDismissable from '../hooks/dismissable';

const html = registerHtml({
	XControl,
});

const FilterTag: TramOneComponent = ({ category, value, highlight }) => {
	const { onHoveringDismiss, dismissClass } = useDismissable();
	const { getPrefix, getType, removeValue } = useFilters(category);

	const onRemoveSelected = () => {
		onHoveringDismiss(false);
		removeValue(value);
	};

	const highlightClass = highlight ? 'highlighted-tag' : 'tag';

	return html`
		<span class="FilterTag ${highlightClass} ${getType()}-${highlightClass} ${dismissClass}">
			${getPrefix()}${value}
			<XControl onselect=${onRemoveSelected} onhover=${onHoveringDismiss} />
		</span>
	`;
};

export default FilterTag;
