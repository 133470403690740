import { registerHtml, TramOneComponent } from 'tram-one';
import { useFilters } from '../hooks/keywords';
import FilterForm from './FilterForm';
import FilterTags from './FilterTags';

const html = registerHtml({
	FilterForm,
	FilterTags,
});

const FilterGroup: TramOneComponent = ({ category }) => {
	const { setType, getType, getSavedType } = useFilters(category);

	const setFilterType = (event) => {
		setType(event.target.value);
	};

	const changed = getType() !== getSavedType();
	const highlightClass = changed ? 'highlight' : '';

	return html`
		<section class="FilterGroup">
			<h3 class="filter-header-and-type ${highlightClass}">
				<span class="filter-control-header">${category}</span>
				<select class="filter-type-dropdown" name="filter-type" onchange=${setFilterType}>
					<option ${getType() == 'blacklist' ? 'selected' : ''} value="blacklist">Block List</option>
					<option ${getType() == 'whitelist' ? 'selected' : ''} value="whitelist">Allow List</option>
				</select>
			</h3>
			<FilterForm category=${category} filterType=${getType()} />
			<FilterTags category=${category} />
		</section>
	`;
};

export default FilterGroup;
