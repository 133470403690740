import { useStore } from 'tram-one';

const useDismissable = () => {
	const dismissStore = useStore({ isDismissing: false });

	const onHoveringDismiss = (isOver) => {
		dismissStore.isDismissing = isOver;
	};

	const dismissClass = dismissStore.isDismissing ? 'dismissing' : '';

	return {
		onHoveringDismiss,
		dismissClass,
	};
};

export default useDismissable;
